<script setup>
// https://github.com/juareznasato/vuetify-money/blob/master/src/components/VuetifyMoney.vue
const props = defineProps({
    modelValue: {
        // type: String,
        type: [String, Number],
        default: "0"
    },
    label: {
        type: String,
        default: ""
    },
    placeholder: {
        type: String,
        default: ""
    },
    readonly: {
        type: Boolean,
    },
    disabled: {
        type: Boolean,
    },
    clearable: {
        type: Boolean,
    },
    variant: {
        type: String,
        default: "filled"
    },
    rounded: {
        type: Boolean,
        default: false
    },
    color: {
        type: String,
        default: ""
    },
    baseColor: {
        type: String,
        default: ""
    },
    density: {
        type: String,
        default: ""
    },
    rules: {
        default: [],
    },
    hideDetails: {
        type: [String, Boolean],
        default: false,
    },
    valueOptions: {
        type: Object,
        default: function () {
            return {
                min: 0,
                minEvent: "SetValueMin"
            };
        }
    },
})
const emit = defineEmits(["update:modelValue", "SetValueMin", "SetValueMax", "click:clear", "change"])
const input = ref()

const cmpSimpleValue = computed({
    get: function () {
        return props.modelValue
    },
    set: function (newValue) {
        if (isNaN(parseFloat(newValue))) {
            newValue = 0
        } else {
            newValue = parseFloat(newValue).toFixed(2)
        }
        emit("update:modelValue", newValue);
    }
})

function onBlur() {
    if (
        props.modelValue.length === 0 ||
        parseFloat(props.modelValue) <= props.valueOptions.min
    )
        emit(
            props.valueOptions.minEvent || "SetValueMin",
            props.valueOptions.min
        );

    if (
        props.valueOptions.max &&
        parseFloat(props.modelValue) >= props.valueOptions.max
    )
        emit(
            props.valueOptions.maxEvent || "SetValueMax",
            props.valueOptions.max
        );
}
</script>
<template>
    <v-text-field v-model.lazy="cmpSimpleValue" type="number" step="0.01" ref="input" :label="props.label"
        :placeholder="props.placeholder" :variant="props.variant" :readonly="props.readonly" :color="props.color"
        :base-color="props.baseColor" :density="props.density" :disabled="props.disabled" :rounded="props.rounded"
        :rules="props.rules" :hide-details="props.hideDetails" @blur="onBlur" class="money-right"
        @change="emit('change')" prepend-inner-icon="icon-dollar-sign" :clearable="props.clearable"
        @click:clear="emit('click:clear')" />
</template>
